<script setup lang="ts">
import { onClickOutside, useElementBounding } from '@vueuse/core';

export type Dropdown = {
  key: string | number;
  label: string;
  icon?: string;
  iconClass?: string;
  disabled?: boolean;
};

// Props & Emits
const emits = defineEmits(['selectItem']);
const props = defineProps<{
  placeholder?: string;
  items: Dropdown[];
  type?: 'icon';
  icon?: string;
  menuItemsClass?: string;
  searchable?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  disabled?: boolean;
  noSeparation?: boolean;
  fitToSelect?: boolean;
}>();

// Data
const value = defineModel();
const search = ref<string>('');
const input = ref();
const open = ref<boolean>(false);
const container = ref(null);
onClickOutside(container, () => (open.value = false));

const button = ref<HTMLElement | null>(null);

const { left, width, update, top, height } = useElementBounding(button);

const elements = ref<HTMLElement | null>(null);
const { width: elementsWidth, update: reload } = useElementBounding(elements);

// Methods
const clickItem = (item: Dropdown) => {
  if (item.disabled || props.disabled) return;
  emits('selectItem', item.key);
  value.value = item.key;
  search.value = '';
  open.value = false;
};

const openMenu = async () => {
  if (props.disabled) return;
  await update();
  await reload();
  open.value = !open.value;
  if (props.searchable) {
    nextTick(() => {
      setTimeout(() => {
        if (input.value) input.value.focus();
      }, 100);
    });
  }
};

// Computed
const itemsSearch = computed(() => {
  if (search.value === '') return props.items;
  return props.items.filter((item) => {
    return item.label.toLowerCase().includes(search.value.toLowerCase());
  });
});

const getSize = computed(() => {
  if (props.size === 'xs') return 'h-[20px]';
  if (props.size === 'sm') return 'h-[24px]';
  if (props.size === 'md') return 'h-[30px]';
  if (props.size === 'lg') return 'h-[30px]';
  return 'h-[30px]';
});

const getFit = computed(() => {
  if (props.fitToSelect) return `width: ${width.value}px;`;
  return '';
});
</script>

<template>
  <div ref="container" class="relative inline-block text-left !normal-case">
    <div
      v-if="!$slots.button"
      ref="button"
      :disabled="disabled"
      :class="[
        getSize,
        size === 'xs' ? '' : 'border border-gray-200 px-3',
        disabled ? 'cursor-not-allowed !border-gray-100' : 'cursor-pointer',
        open
          ? 'border-primary-500 ring-green-100 ring-[2px]'
          : 'border-gray-200 hover:border-gray-300 hover:ring-[1px] hover:ring-gray-100',
      ]"
      class="flex z-0 items-center w-full justify-between rounded-md bg-white text-sm font-medium group-hover:text-gray-700 text-gray-600"
      @click="openMenu()"
    >
      <p v-if="value" class="text-sm" :class="props.disabled ? 'text-gray-400' : 'text-gray-600'">
        {{ placeholder }}{{ placeholder ? (noSeparation ? ' ' : ': ') : '' }}
        {{ items.find((element) => element.key === value)?.label || '' }}
      </p>
      <p v-else class="text-sm">
        {{ placeholder ?? 'Filtrer' }}
      </p>
      <ui-icon
        :class="props.disabled ? 'stroke-gray-400' : 'stroke-gray-600'"
        class="ml-2 h-4 w-4 text-gray-600 group-hover:text-gray-700"
        name="ChevronDown"
      />
    </div>
    <div v-else>
      <slot name="button" />
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-y-95"
      enter-to-class="transform opacity-100 scale-y-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-y-100"
      leave-to-class="transform opacity-0 scale-y-95"
    >
      <div
        v-if="open"
        ref="elements"
        :class="[searchable ? 'divide-y divide-gray-100' : '']"
        :style="`left: ${Math.round(left) - Math.round(elementsWidth) + width}px; ${getFit}; top: ${top + height}px;`"
        class="fixed origin-top-right bg-white z-[9999] mt-2 p-1 divide-y divide-gray-100 rounded-[8px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <input
          v-if="searchable"
          ref="input"
          v-model="search"
          aria-autocomplete="both"
          autocomplete="off"
          required
          :placeholder="$t('global.search')"
          class="p-[12px] w-full mb-1 border-gray-300 focus:border-primary-500 focus:ring-primary-100 h-[32px] text-sm autofill:bg-white bg-white outline-none focus:ring-[2px] ring-offset-0 border-[1px] text-[14px] text-gray-700 rounded-md"
          type="text"
        />
        <div class="overflow-auto max-h-[150px]">
          <div v-if="itemsSearch.length === 0" class="block px-4 py-2 text-sm text-center">
            {{ $t('global.no_result') }}
          </div>
          <div v-for="item in itemsSearch" :key="item.key" :disabled="item.disabled" class="rounded-[4px]">
            <slot name="item" :item="item">
              <div
                class="select-none cursor-pointer flex items-center font-normal rounded"
                :class="[
                  item.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100',
                  value === item.key ? 'bg-gray-100 text-gray-900' : 'text-gray-700 hover:bg-gray-50',
                  size === 'sm' ? 'px-2 py-1 text-sm' : 'block px-4 py-2 text-sm',
                ]"
                @click="clickItem(item)"
              >
                <div>
                  <component :is="item.icon" v-if="item.icon" :class="item.iconClass" class="w-5 h-5 mr-4 md:mr-2 md:w-4 md:h-4" />
                </div>

                <p class="whitespace-nowrap normal-case text-sm">
                  {{ item.label }}
                </p>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
